
import { Component, Vue } from "vue-property-decorator";
import TitleBasic from "@/components/layout/TitleBasic.vue";
import { Route as VueRoute, NavigationGuardNext } from "vue-router";
import { Event } from "@/interfaces/services/event";
import { eventService } from "@/services/event";
import { Route } from "@/router/routes";
import { errorService } from "@/services/error";
import { Error } from "@/interfaces/services/error";
import { interfaceService } from "@/services/interface";

@Component({
  components: {
    TitleBasic
  }
})
export default class Details extends Vue {
  loading = false;
  error: string | null = null;
  event: Event | null = null;
  bookmarksPage = 1;
  bookmarksPerPage = 10;

  errorsLoading = true;
  errorsError: string | null = null;
  errors: Error[] = [];
  errorsCount = 0;
  errorsPage = 1;
  errorsPerPage = 10;

  refreshInterval: number | null = null;
  refreshIntervalMs = 5000;

  async beforeRouteEnter(
    to: VueRoute,
    from: VueRoute,
    next: NavigationGuardNext
  ) {
    const id = to.params.id;
    if (id) {
      let event: Event | null = null;
      try {
        event = await eventService.get(id);
      } catch (_) {
        // no-op
      }
      if (event) {
        return next(vm => {
          (vm as Details).event = event as Event;
          (vm as Details).initialize();
        });
      }
    }
    next({ name: Route.Events });
  }

  get id() {
    return this.event?.id;
  }

  get title() {
    return this.event?.externalId;
  }

  get createdAtFormatted() {
    return this.event ? this.getDisplayDateFormat(this.event.createdAt) : null;
  }

  get hasLastUpdatedAt() {
    return (
      this.event &&
      this.event.lastUpdatedAt &&
      this.event.createdAt !== this.event.lastUpdatedAt
    );
  }

  get lastUpdatedAt() {
    return this.event && this.event?.lastUpdatedAt
      ? new Date(this.event?.lastUpdatedAt)
      : null;
  }

  get lastUpdatedAtFormatted() {
    return this.event && this.event.lastUpdatedAt
      ? this.getDisplayDateFormat(this.event.lastUpdatedAt)
      : null;
  }

  get isLastUpdatedAtFresh() {
    return (
      this.lastUpdatedAt && this.lastUpdatedAt.getTime() > Date.now() - 60000
    );
  }

  get endpoint() {
    return this.event ? this.event.endpoint : null;
  }

  get edata() {
    return this.event?.data || {};
  }

  get bookmarksCount() {
    return (this.edata.markers || []).length;
  }

  get bookmarksLength() {
    return Math.ceil(this.bookmarksCount / this.bookmarksPerPage);
  }

  get bookmarks() {
    return this.edata.markers
      ? this.edata.markers
          .sort((a: any, b: any) => b.timestampStart - a.timestampStart)
          .slice(
            (this.bookmarksPage - 1) * this.bookmarksPerPage,
            this.bookmarksPage * this.bookmarksPerPage
          )
      : [];
  }

  get hasBookmarks() {
    return this.bookmarksCount > 0;
  }

  get hasErrors() {
    return this.errorsCount > 0;
  }

  get errorsLength() {
    return Math.ceil(this.errorsCount / this.errorsPerPage);
  }

  initialize() {
    this.errorsLoadData();
    this.setRefreshInterval();
  }

  setRefreshInterval() {
    this.refreshInterval = setInterval(this.refresh, this.refreshIntervalMs);
  }

  refresh() {
    this.itemLoadData();
    this.errorsLoadData();
  }

  destroyed() {
    if (this.refreshInterval !== null) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
  }

  itemLoadData() {
    if (!this.id) {
      return;
    }

    this.error = null;
    this.loading = true;
    eventService
      .get(this.id)
      .then(event => (this.event = event))
      .catch(e => (this.error = e.code))
      .finally(() => (this.loading = false));
  }

  errorsLoadData() {
    this.errorsError = null;
    this.errorsLoading = true;
    let listSearchIds = undefined;
    if (this.event) {
      listSearchIds = [this.event.id, this.event.externalId];
    }
    errorService
      .list(this.errorsPerPage, this.errorsPage, {
        ep: this.endpoint || undefined,
        s: listSearchIds
      })
      .then(data => {
        this.errors = data.items || [];
        this.errorsCount = data.count;
      })
      .catch(e => (this.errorsError = e.code))
      .finally(() => (this.errorsLoading = false));
  }

  getDisplayDateFormat(date: string) {
    return new Date(date).toLocaleString(interfaceService.getLanguage(), {
      dateStyle: "medium",
      timeStyle: "medium"
    });
  }

  containsExternalIdAfterLastUpdate(error: Error) {
    const eventLastUpdatedAt = this.lastUpdatedAt;
    return (
      eventLastUpdatedAt !== null &&
      this.event &&
      error.message.includes(this.event.externalId) &&
      new Date(error.createdAt) > eventLastUpdatedAt
    );
  }
}
