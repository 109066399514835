import { Error } from "@/interfaces/services/error";
import { ListItems } from "@/interfaces/services/generic";
import { apiService } from "./api";

class ErrorService {
  protected getEndpoint(...parts: string[]) {
    return "errors/" + (parts.length ? parts.join("/") : "");
  }

  async list(
    perPage: number,
    page: number,
    params?: { s?: string[]; ep?: string }
  ) {
    return (
      await apiService.get<ListItems<Error>>(this.getEndpoint(), {
        params: { ...params, perPage, page }
      })
    ).data;
  }
}

export const errorService = new ErrorService();
