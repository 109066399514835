import { Event, EventOverview } from "@/interfaces/services/event";
import { ListItems } from "@/interfaces/services/generic";
import { apiService } from "./api";

class EventService {
  protected getEndpoint(...parts: string[]) {
    return "events/" + (parts.length ? parts.join("/") : "");
  }

  async list(
    perPage: number,
    page: number,
    params?: { s?: string; ep?: string }
  ) {
    return (
      await apiService.get<ListItems<EventOverview>>(this.getEndpoint(), {
        params: { ...params, perPage, page }
      })
    ).data;
  }

  async get(id: string) {
    return (await apiService.get<Event>(this.getEndpoint("m", id))).data;
  }
}

export const eventService = new EventService();
